var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import Badge from '@shared/ui/badges/Badge';
import { numberToFixed2 } from '@utils/DisplayValueHelpers';
const withMddBadge = (TypographyComponent) => {
    const MddBadge = (_a) => {
        var { mdd, badgeVariant = 'tinted', badgeSize = 'medium', badgeStartAdornment = undefined, badgeEndAdornment = undefined } = _a, props = __rest(_a, ["mdd", "badgeVariant", "badgeSize", "badgeStartAdornment", "badgeEndAdornment"]);
        const displayValue = useMemo(() => `${numberToFixed2(mdd)} %`, [mdd]);
        const color = useMemo(() => {
            let resultColor = 'error';
            // mdd value should always be negative (x < 0)
            if (mdd >= -15 && mdd <= 0) {
                resultColor = 'success';
            }
            else if (mdd >= -50 && mdd < -15) {
                resultColor = 'warning';
            }
            return resultColor;
        }, [mdd]);
        return (_jsx(Badge, { variant: badgeVariant, color: color, size: badgeSize, startAdornment: badgeStartAdornment, endAdornment: badgeEndAdornment, children: _jsx(TypographyComponent
            // eslint-disable-next-line react/jsx-props-no-spreading
            , Object.assign({}, props, { children: displayValue })) }));
    };
    return MddBadge;
};
export default withMddBadge;
