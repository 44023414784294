const getBadgeStyles = (theme) => ({
    bySizes: {
        small: {
            padding: '2px 5px',
            gap: '4px',
        },
        medium: {
            padding: '4px 8px',
            gap: '4px',
        },
        large: {
            padding: '6px 10px',
            gap: '6px',
        },
    },
    byVariants: {
        filled: {
            primary: {
                color: '#FFFFFF',
                backgroundColor: '#6488F0',
            },
            secondary: {
                color: '#FFFFFF',
                backgroundColor: '#687076',
            },
            success: {
                color: '#FFFFFF',
                backgroundColor: '#30A46C',
            },
            error: {
                color: '#FFFFFF',
                backgroundColor: '#DC3D43',
            },
            warning: {
                color: '#FFFFFF',
                backgroundColor: '#FFA01C',
            },
        },
        outline: {
            primary: {
                color: '#6488F0',
                backgroundColor: 'transparent',
                border: '1px solid #C6D4F9',
            },
            secondary: {
                color: '#687076',
                backgroundColor: 'transparent',
                border: '1px solid #7E868C',
            },
            success: {
                color: '#30A46C',
                backgroundColor: 'transparent',
                border: '1px solid #9ED9B3',
            },
            error: {
                color: '#DC3D43',
                backgroundColor: 'transparent',
                border: '1px solid #F9C6C6',
            },
            warning: {
                color: '#FFA01C',
                backgroundColor: 'transparent',
                border: '1px solid #FFD386',
            },
        },
        tinted: {
            primary: {
                color: '#6488F0',
                backgroundColor: '#F8FAFF',
            },
            secondary: {
                color: '#687076',
                backgroundColor: '#F8F9FA',
            },
            success: {
                color: '#30A46C',
                backgroundColor: '#ECFBF1',
            },
            error: {
                color: '#DC3D43',
                backgroundColor: '#FFF6F6',
            },
            warning: {
                color: '#FFA01C',
                backgroundColor: '#FFF4D5',
            },
        },
        plain: {
            primary: {
                color: '#6488F0',
                backgroundColor: 'transparent',
            },
            secondary: {
                color: '#687076',
                backgroundColor: 'transparent',
            },
            success: {
                color: '#30A46C',
                backgroundColor: 'transparent',
            },
            error: {
                color: '#DC3D43',
                backgroundColor: 'transparent',
            },
            warning: {
                color: '#FFA01C',
                backgroundColor: 'transparent',
            },
        },
    },
});
export default getBadgeStyles;
